exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-course-tsx": () => import("./../../../src/pages/course.tsx" /* webpackChunkName: "component---src-pages-course-tsx" */),
  "component---src-pages-forgot-password-action-tsx": () => import("./../../../src/pages/forgot-password-action.tsx" /* webpackChunkName: "component---src-pages-forgot-password-action-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-modules-index-tsx": () => import("./../../../src/pages/modules/index.tsx" /* webpackChunkName: "component---src-pages-modules-index-tsx" */),
  "component---src-pages-modules-sanity-content-doc-slug-current-index-tsx": () => import("./../../../src/pages/modules/{SanityContent.doc__slug__current}/index.tsx" /* webpackChunkName: "component---src-pages-modules-sanity-content-doc-slug-current-index-tsx" */),
  "component---src-pages-modules-sanity-content-doc-slug-current-sanity-content-slug-current-tsx": () => import("./../../../src/pages/modules/{SanityContent.doc__slug__current}/{SanityContent.slug__current}.tsx" /* webpackChunkName: "component---src-pages-modules-sanity-content-doc-slug-current-sanity-content-slug-current-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-sanity-post-slug-current-tsx": () => import("./../../../src/pages/{SanityPost.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-post-slug-current-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-product-post-tsx": () => import("./../../../src/templates/product-post.tsx" /* webpackChunkName: "component---src-templates-product-post-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

